import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import * as S from '../../styles/workout-video.styles'
import * as I from '../../components/shared/Icons'
import Layout from '../../templates/LiveLayout'
import * as session from '../../services/session'
import { HOME_ROUTE, TRAINER_RECORDINGS } from '../../constants/routes'
import { SectionTag } from '../../components/shared'
import { DESKTOP_WIDTH } from '../../constants/measurements'
import { workoutSelector } from '../../redux/selectors'
import { RootState } from '../../redux/reducers/rootReducers'
import { onFetchWorkoutById as onFetchWorkoutByIdAction } from '../../redux/actions'
import SpotifyButton from '../../components/shared/SpotifyButton'
import { withErrorHandler } from '../../components/errorHandler'
import CircularProgress from '@material-ui/core/CircularProgress'
import { navigate, PageRendererProps } from 'gatsby'
import { PRIMARY } from '../../constants/colors'

interface WorkoutVideoProps {
  location: { state: { workoutId: number }; search: string }
}

const WorkoutVideo: React.FC<Props> = (props: Props) => {
  session.redirectIfNotLoggedIn(HOME_ROUTE)
  const { workout, onFetchWorkoutById } = props

  const { search } = props.location
  const workoutId = search.split('?id=').pop()

  useEffect(() => {
    onFetchWorkoutById(Number(workoutId || 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isDesktopOrSmaller = useMediaQuery({
    query: `(max-width: ${DESKTOP_WIDTH}px)`,
  })

  return (
    <Layout title="View Workout" location={props.location}>
      <S.Header onClick={() => navigate(TRAINER_RECORDINGS)}>
        <I.ChevronLeft lg color={PRIMARY} />
        <div style={{ marginLeft: 12 }}>Back</div>
      </S.Header>
      <SectionTag>
        <div
          style={
            !isDesktopOrSmaller ? { paddingLeft: '5%', paddingRight: '5%' } : {}
          }
        >
          {!workout ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <S.PlayerWrapper>
                <S.Player
                  url={
                    process.env.GATSBY_VIDEO_TYPE === 'youtube'
                      ? `https://www.youtube.com/watch?v=${workout.videoUrl}`
                      : workout.videoUrl
                  }
                  controls={true}
                  volume={1}
                  muted={false}
                  playing={true}
                  width="100%"
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              </S.PlayerWrapper>

              <S.Row>
                <S.VideoInfoWrap
                  style={
                    isDesktopOrSmaller
                      ? { paddingLeft: '3%', paddingRight: '3%' }
                      : {}
                  }
                >
                  <S.Primary>{workout.title}</S.Primary>
                  <S.Secondary>with {workout.trainerName}</S.Secondary>
                  {workout.headerOne && (
                    <S.NoteHeader>{workout.headerOne}</S.NoteHeader>
                  )}
                  {workout.description.split('\n').map((i, key) => (
                    <S.Note key={key}>{i}</S.Note>
                  ))}
                  {workout.headerTwo && (
                    <S.NoteHeader>{workout.headerTwo}</S.NoteHeader>
                  )}
                  {workout.descriptionTwo &&
                    workout.descriptionTwo
                      .split('\n')
                      .map((i, key) => <S.Note key={key}>{i}</S.Note>)}
                </S.VideoInfoWrap>
                {workout.spotifyLink && (
                  <SpotifyButton spotifyLink={workout.spotifyLink} />
                )}
              </S.Row>
            </div>
          )}
        </div>
      </SectionTag>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  workout: workoutSelector(state),
})

const mapDispatchToProps = {
  onFetchWorkoutById: onFetchWorkoutByIdAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WorkoutVideoProps &
  PageRendererProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(WorkoutVideo))
